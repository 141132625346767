const COUNT_ABBRS = ['', 'k', 'm', 'g', 't', 'p', 'e', 'z', 'y'];
// Adapted from https://stackoverflow.com/a/46134583
export function abbreviateNumber(value, maxDigits = 3) {
    const i = 0 === value ? value : Math.floor(Math.log(value) / Math.log(1000));
    const result = Math.floor(value / Math.pow(1000, i)).toLocaleString();
    return `${result.substr(0, i > 0 ? maxDigits : result.length)}${COUNT_ABBRS[i]}`;
}
// Based on https://stackoverflow.com/questions/29182244/convert-a-string-to-a-template-string
// To fit with standard translation string replacements with uses `{0}`, `{1}`, etc,
// this method will interpolate a translation string with JS expressions.
export function interpolate(string, params) {
    const subject = string.replace(/{/g, '${s');
    const names = Object.keys(params).map(v => `s${v}`);
    const values = Object.values(params);
    return new Function(...names, `return \`${subject}\`;`)(...values);
}
export const capitalizeFirstLetter = (text) => text.charAt(0).toUpperCase() + text.slice(1);
export const toCamelCase = (str) => str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
export const toPascalCase = (str) => capitalizeFirstLetter(toCamelCase(str));
export const textToSlug = (text) => text.replace(/[^a-zA-Z0-9_\s-]/gi, '').toLowerCase().trim().replace(/\s/gi, '-');
export const inflectorSlug = (input, caseType, seperator) => {
    if (typeof caseType === 'undefined' || caseType !== 'lower') {
        caseType = 'upper';
    }
    if (typeof seperator === 'undefined') {
        seperator = '_';
    }
    if (typeof input !== 'string') {
        input = String(input);
    }
    input = input.replace(/\s/g, seperator).replace(/[^\w-]+/g, '');
    if (caseType == 'upper') {
        return input.toUpperCase();
    }
    else {
        return input.toLowerCase();
    }
};
