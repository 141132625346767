import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "container f-inter" };
const _hoisted_2 = ["id", "name", "value", "disabled", "required", "maxlength", "placeholder", "rows"];
const _hoisted_3 = ["for"];
const _hoisted_4 = { class: "footer" };
const _hoisted_5 = {
    key: 0,
    class: "error"
};
import { computed, ref } from 'vue';
import { v4 as uuid } from 'uuid';
export default /*@__PURE__*/ _defineComponent({
    __name: 'TextArea',
    props: {
        name: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        footer: {
            type: String,
            default: '',
        },
        maxlength: {
            type: Number,
            default: null,
        },
        rows: {
            type: Number,
            default: 4,
        },
        /**
         * Used by vue to bind the input value to `v-model` set on the component.
         */
        modelValue: {
            type: String,
            default: ''
        },
        error: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        }
    },
    emits: ['update:model-value', 'focus'],
    setup(__props, { emit: __emit }) {
        const uniqueID = uuid();
        /**
         * Note: This component works with `v-model` so you can use `<form-input v-model="inputVar" />` to bind the input
         * value to some local var.
         */
        const props = __props;
        const emit = __emit;
        const scrolled = ref(false);
        const classes = computed(() => {
            return {
                "text-area": true,
                "error": props.error.length > 0
            };
        });
        const labelClasses = computed(() => {
            return {
                "label": true,
                "scrolled": scrolled.value
            };
        });
        const debounce = () => {
            const timeout = null;
            return function (fnc, args, delayMs) {
                clearTimeout(timeout);
                timeout.value = setTimeout(() => fnc(...args), delayMs || 500);
            };
        };
        const onInput = (event) => {
            emit('update:model-value', event.target.value);
        };
        const onFocus = () => {
            emit('focus');
        };
        const onScroll = (event) => {
            const scrollDist = event.target.scrollTop;
            if (scrollDist > 0) {
                scrolled.value = true;
                return;
            }
            scrolled.value = false;
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("textarea", {
                    id: _unref(uniqueID),
                    name: __props.name,
                    value: __props.modelValue,
                    class: _normalizeClass(classes.value),
                    disabled: __props.disabled,
                    required: __props.required,
                    maxlength: __props.maxlength,
                    placeholder: __props.placeholder || ' ',
                    rows: __props.rows,
                    onInput: onInput,
                    onFocus: onFocus,
                    onScroll: _cache[0] || (_cache[0] = ($event) => (debounce(onScroll, [$event], 25)))
                }, null, 42, _hoisted_2),
                _createElementVNode("label", {
                    for: _unref(uniqueID),
                    class: _normalizeClass(labelClasses.value)
                }, _toDisplayString(__props.label), 11, _hoisted_3),
                _createElementVNode("div", _hoisted_4, _toDisplayString(__props.footer), 1),
                (Boolean(__props.error))
                    ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(__props.error), 1))
                    : _createCommentVNode("", true)
            ]));
        };
    }
});
