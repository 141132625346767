import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { computed, ref, watch } from 'vue';
import FormSelect from './FormSelect.vue';
import ZInput from './ZInput.vue';
import { supportedCurrencies, minimumPricePerCurrency, formatCurrencyStr, formatCurrencyParts, DEFAULT_CURRENCY } from '../../ts/Util/currency';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CurrencyInput',
    props: {
        priceLabel: {
            type: String,
            default: "Price"
        },
        priceTooltip: {
            type: String,
            default: "price"
        },
        currencyLabel: {
            type: String,
            default: "Currency"
        },
        currencies: {
            type: Array,
            default: () => supportedCurrencies
        },
        /** Used by vue to bind the input value to `v-model:currency` on the component. */
        currency: {
            type: String,
            default: DEFAULT_CURRENCY
        },
        /* Used by vue to bind the input value to `v-model:price` set on the component. */
        price: {
            type: [Number, String],
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        },
        reversedOrder: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:currency', 'update:price', 'price:focus', 'price:blur'],
    setup(__props, { emit: __emit }) {
        const DEFAULT_ERRORS = {
            required: (label) => {
                return `${label} is required.`;
            },
            minimum: (label, min) => {
                return `${label} must be at least ${min}.`;
            },
            invalid: (label) => {
                return `Please enter a valid ${label}`;
            }
        };
        const props = __props;
        const emit = __emit;
        const focusHandler = ($event) => {
            if (parseFloat(priceValue.value) == 0.0) {
                priceValue.value = '';
            }
            emit('price:focus', $event);
        };
        const blurHandler = ($event) => {
            priceValue.value = formatPrice(priceValue.value);
            emit('price:blur', $event);
        };
        const currencyDetails = (type) => {
            let parts = formatCurrencyParts(1, currencyValue.value);
            return parts.find(part => part.type == type);
        };
        const stripNonNumbers = (price) => {
            let decimal = currencyDetails('decimal');
            if (typeof decimal === 'undefined') {
                return '';
            }
            let nonNumber = new RegExp(`[^0-9\\${decimal.value}]`, 'g');
            price = price.replace(nonNumber, "");
            if (price == decimal.value) {
                return '';
            }
            return price;
        };
        const formatPrice = (price) => {
            if (typeof price === 'string') {
                price = stripNonNumbers(price);
                if (price == '') {
                    return '';
                }
                price = parseFloat(price);
            }
            let symbol = currencyDetails('currency');
            if (typeof symbol === 'undefined') {
                return '';
            }
            return formatCurrencyStr(price, currencyValue.value).replace(symbol.value, '').trim();
        };
        const updateCurrency = () => {
            emit('update:currency', currencyValue);
        };
        const currencyValue = ref(props.currency);
        const priceValue = ref(formatPrice(props.price));
        const internalError = ref(props.error);
        const minPricePerCurrency = ref(minimumPricePerCurrency);
        const items = computed(() => {
            let currencies = {};
            for (let i = 0; i < props.currencies.length; i++) {
                let currency = props.currencies[i];
                currencies[currency] = currency;
            }
            return currencies;
        });
        watch(() => props.currency, (newCurrency) => {
            currencyValue.value = newCurrency;
        });
        watch(() => priceValue.value, (newPrice, oldPrice) => {
            let formatted = formatPrice(newPrice);
            let value = parseFloat(stripNonNumbers(formatted));
            if (props.required) {
                if (newPrice == '') {
                    internalError.value = DEFAULT_ERRORS.required(props.priceLabel);
                }
                else if (isNaN(value)) {
                    internalError.value = DEFAULT_ERRORS.invalid(props.priceLabel);
                }
                else if (minPricePerCurrency.value[currencyValue.value] > value) {
                    internalError.value = DEFAULT_ERRORS.minimum(props.priceLabel, minPricePerCurrency.value[currencyValue.value]);
                }
                else {
                    internalError.value = props.error;
                }
            }
            if (newPrice !== oldPrice) {
                emit('update:price', Number.isNaN(value) ? null : value);
            }
        });
        watch(() => props.error, (newError) => {
            internalError.value = newError;
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["pc-wrap", { 'pc-wrap-reversed': __props.reversedOrder }])
            }, [
                _createVNode(ZInput, {
                    modelValue: priceValue.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((priceValue).value = $event)),
                    name: "price",
                    type: "text",
                    class: _normalizeClass(["price-input", { 'with-left-margin': __props.reversedOrder }]),
                    label: __props.priceTooltip,
                    error: internalError.value,
                    disabled: __props.disabled,
                    onFocus: focusHandler,
                    onBlur: blurHandler
                }, null, 8, ["modelValue", "class", "label", "error", "disabled"]),
                _createVNode(FormSelect, {
                    modelValue: currencyValue.value,
                    "onUpdate:modelValue": [
                        _cache[1] || (_cache[1] = ($event) => ((currencyValue).value = $event)),
                        updateCurrency
                    ],
                    class: _normalizeClass(["currency-input", { 'with-left-margin': !__props.reversedOrder }]),
                    name: "currency",
                    "item-alignment": "center",
                    "scroll-after": 10,
                    label: __props.currencyLabel,
                    items: items.value,
                    disabled: __props.disabled
                }, null, 8, ["modelValue", "class", "label", "items", "disabled"])
            ], 2));
        };
    }
});
