import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot } from "vue";
const _hoisted_1 = { class: "input-container" };
const _hoisted_2 = ["id", "value", "name", "type", "placeholder", "maxlength", "min", "max", "required", "disabled", "autocomplete", "readonly"];
const _hoisted_3 = ["for"];
const _hoisted_4 = {
    key: 0,
    class: "required-star"
};
const _hoisted_5 = {
    key: 2,
    class: "zInput__right-slot"
};
const _hoisted_6 = ["innerHTML"];
import { reactive, computed, useSlots, ref, watch } from 'vue';
import { isSafari } from '@ts/Util/responsiveness';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ZInput',
    props: {
        label: {
            type: String,
            default: ""
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        inputId: {
            type: String,
            default: null
        },
        name: {
            type: String,
            required: true
        },
        modelValue: {
            type: [String, Number],
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        },
        maxlength: {
            type: Number,
            default: null
        },
        max: {
            type: [Number, String],
            default: null,
        },
        min: {
            type: [Number, String],
            default: null,
        },
        error: {
            type: String,
            default: ''
        },
        showRequired: {
            type: Boolean,
            default: false
        },
        autocomplete: {
            type: String,
            default: 'on'
        },
        readonly: {
            type: Boolean,
            default: false
        },
        allowPickerTrigger: {
            type: Boolean,
            default: false
        },
        lastTriggerPickerTime: {
            type: Number,
            default: () => Date.now()
        }
    },
    emits: ['invalid', 'update:model-value', 'focus', 'blur', 'change'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const slots = useSlots();
        const hasIcon = computed(() => !!slots.icon);
        const input = ref(null);
        const onInvalid = (event) => {
            state.hasError = true;
            emit('invalid', event);
        };
        const onBlur = (event) => emit('blur', event);
        const onChange = (event) => emit('change', event);
        const onInput = (event) => emit('update:model-value', event.target.value);
        const onFocus = (event) => emit('focus', event);
        const onClick = () => input.value.focus();
        watch(() => props.lastTriggerPickerTime, (newVal, oldVal) => {
            // Some browsers, ie. FF have trouble with the custom styles on the input
            // This helps ensure that native datepickers, etc. can be triggered
            if (!props.allowPickerTrigger || oldVal >= newVal) {
                return;
            }
            input.value.focus();
            if (props.type !== 'date') {
                return;
            }
            input.value.showPicker();
        });
        const state = reactive({
            hasError: false
        });
        watch(() => props.error, () => {
            state.hasError = props.error !== '';
        }, { immediate: true });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["zInput", {
                        'zInput__error': state.hasError,
                        'is-safari': _unref(isSafari)()
                    }])
            }, [
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("input", {
                        id: __props.inputId,
                        ref_key: "input",
                        ref: input,
                        class: _normalizeClass(["zInput__input", {
                                'with-icon': hasIcon.value
                            }]),
                        value: __props.modelValue,
                        name: __props.name,
                        type: __props.type,
                        placeholder: __props.placeholder || ' ',
                        maxlength: __props.maxlength,
                        min: __props.min,
                        max: __props.max,
                        required: __props.required,
                        disabled: __props.disabled,
                        autocomplete: __props.autocomplete,
                        readonly: __props.readonly,
                        onInput: onInput,
                        onFocus: onFocus,
                        onInvalid: _cache[0] || (_cache[0] = ($event) => (onInvalid($event))),
                        onBlur: _cache[1] || (_cache[1] = ($event) => (onBlur($event))),
                        onChange: _cache[2] || (_cache[2] = ($event) => (onChange($event)))
                    }, null, 42, _hoisted_2),
                    (__props.showLabel)
                        ? (_openBlock(), _createElementBlock("label", {
                            key: 0,
                            for: __props.name,
                            class: "zInput__label z-b4",
                            onClick: onClick
                        }, [
                            _createTextVNode(_toDisplayString(__props.label) + " ", 1),
                            (__props.required && __props.showRequired)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_4, " * "))
                                : _createCommentVNode("", true)
                        ], 8, _hoisted_3))
                        : _createCommentVNode("", true),
                    (hasIcon.value)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass([
                                'zInput__icon--container',
                                {
                                    'zInput__error': state.hasError,
                                    'with-label': __props.showLabel
                                }
                            ])
                        }, [
                            _renderSlot(_ctx.$slots, "icon", { class: "icon" })
                        ], 2))
                        : _createCommentVNode("", true),
                    (_ctx.$slots['right-slot'])
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _renderSlot(_ctx.$slots, "right-slot")
                        ]))
                        : _createCommentVNode("", true)
                ]),
                (state.hasError)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: "zInput__error",
                        innerHTML: __props.error
                    }, null, 8, _hoisted_6))
                    : _createCommentVNode("", true)
            ], 2));
        };
    }
});
