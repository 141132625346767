/**
 * Format a price into a locale-aware currency string
 *
 * formatCentsSimplified as true removes cents when it's X.00 (same as PHP helper)
 */
export const formatCurrencyStr = function (price, currencyCode = "USD", locale, formatCentsSimplified = false) {
    const options = {
        style: 'currency',
        currency: currencyCode
    };
    if (formatCentsSimplified && (price - Math.floor(price)) === 0.0) {
        options.minimumFractionDigits = 0;
    }
    // undefined locale param defaults to client locale
    let formatter = new Intl.NumberFormat(locale, options);
    return formatter.format(price);
};
/*
* Returns the available parts of the formatted currency
* For available properties and info, see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/formatToParts
*/
export const formatCurrencyParts = function (price, currencyCode = "USD", locale) {
    let formatter = new Intl.NumberFormat(locale, { style: 'currency', currency: currencyCode });
    return formatter.formatToParts(price);
};
/**
 * Default currency when one is not provided
 */
export const DEFAULT_CURRENCY = "USD";
/**
 * Validate a currency value against supported currencies
 */
export const validateCurrency = (val) => {
    return supportedCurrencies.includes(val);
};
/*
* Stripe-supported currencies
* Currencies not supported by AMEX are not included in this list
* https://stripe.com/docs/currencies#zero-decimal
*/
export const supportedCurrencies = [
    'USD',
    'AED',
    'ALL',
    'AMD',
    'ANG',
    'ARS',
    'AUD',
    'AWG',
    'AZN',
    'BAM',
    'BBD',
    'BDT',
    'BGN',
    'BIF',
    'BMD',
    'BND',
    'BRL',
    'BSD',
    'BWP',
    'BZD',
    'CAD',
    'CDF',
    'CHF',
    'CNY',
    'CZK',
    'COP',
    'DKK',
    'DOP',
    'DZD',
    'EGP',
    'ETB',
    'EUR',
    'FJD',
    'GBP',
    'GEL',
    'GIP',
    'GMD',
    'GYD',
    'HKD',
    'HRK',
    'HTG',
    'HUF',
    'IDR',
    'ILS',
    'INR',
    'ISK',
    'JMD',
    'JPY',
    'KES',
    'KGS',
    'KHR',
    'KMF',
    'KRW',
    'KYD',
    'KZT',
    'LBP',
    'LKR',
    'LRD',
    'LSL',
    'MAD',
    'MDL',
    'MGA',
    'MKD',
    'MMK',
    'MNT',
    'MOP',
    'MRO',
    'MVR',
    'MWK',
    'MXN',
    'MYR',
    'MZN',
    'NAD',
    'NGN',
    'NOK',
    'NPR',
    'NZD',
    'PGK',
    'PHP',
    'PKR',
    'PLN',
    'QAR',
    'RON',
    'RSD',
    'RUB',
    'RWF',
    'SAR',
    'SBD',
    'SCR',
    'SEK',
    'SGD',
    'SLL',
    'SOS',
    'SZL',
    'THB',
    'TJS',
    'TOP',
    'TRY',
    'TTD',
    'TWD',
    'TZS',
    'UAH',
    'UGX',
    'UZS',
    'VND',
    'VUV',
    'WST',
    'XAF',
    'XCD',
    'YER',
    'ZAR',
    'ZMW'
];
/**
 *
 */
export const minimumPricePerCurrency = {
    USD: 1,
    AED: 1,
    ALL: 1,
    AMD: 1,
    ANG: 1,
    ARS: 1,
    AUD: 1,
    AWG: 1,
    AZN: 1,
    BAM: 1,
    BBD: 1,
    BDT: 1,
    BGN: 1,
    BIF: 1,
    BMD: 1,
    BND: 1,
    BRL: 1,
    BSD: 1,
    BWP: 1,
    BZD: 1,
    CAD: 1,
    CDF: 1,
    CHF: 1,
    CNY: 1,
    CZK: 1,
    COP: 1,
    DKK: 1,
    DOP: 1,
    DZD: 1,
    EGP: 1,
    ETB: 1,
    EUR: 1,
    FJD: 1,
    GBP: 1,
    GEL: 1,
    GIP: 1,
    GMD: 1,
    GYD: 1,
    HKD: 1,
    HRK: 1,
    HTG: 1,
    HUF: 1,
    IDR: 1,
    ILS: 1,
    INR: 1,
    ISK: 1,
    JMD: 1,
    JPY: 1,
    KES: 1,
    KGS: 1,
    KHR: 1,
    KMF: 1,
    KRW: 1,
    KYD: 1,
    KZT: 1,
    LBP: 1,
    LKR: 1,
    LRD: 1,
    LSL: 1,
    MAD: 1,
    MDL: 1,
    MGA: 1,
    MKD: 1,
    MMK: 1,
    MNT: 1,
    MOP: 1,
    MRO: 1,
    MVR: 1,
    MWK: 1,
    MXN: 1,
    MYR: 1,
    MZN: 1,
    NAD: 1,
    NGN: 1,
    NOK: 1,
    NPR: 1,
    NZD: 1,
    PGK: 1,
    PHP: 1,
    PKR: 1,
    PLN: 1,
    QAR: 1,
    RON: 1,
    RSD: 1,
    RUB: 1,
    RWF: 1,
    SAR: 1,
    SBD: 1,
    SCR: 1,
    SEK: 1,
    SGD: 1,
    SLL: 1,
    SOS: 1,
    SZL: 1,
    THB: 1,
    TJS: 1,
    TOP: 1,
    TRY: 1,
    TTD: 1,
    TWD: 1,
    TZS: 1,
    UAH: 1,
    UGX: 1,
    UZS: 1,
    VND: 1,
    VUV: 1,
    WST: 1,
    XAF: 1,
    XCD: 1,
    YER: 1,
    ZAR: 1,
    ZMW: 1,
};
export const paypalCountryToCurrency = {
    'US': 'USD',
    'CA': 'CAD',
    'AR': 'ARS',
    'CO': 'COP',
    'BR': 'BRL',
    'IN': 'INR',
    'ID': 'IDR',
    'GR': 'EUR',
    'DE': 'EUR',
    'CL': 'CLP',
    'CZ': 'CZR',
    'FI': 'EUR',
    'IE': 'EUR',
    'BE': 'EUR',
    'HR': 'EUR',
    'AT': 'EUR',
    'EE': 'EUR',
    'CY': 'EUR',
    'HK': 'HKD',
    'AU': 'AUD',
    'AD': 'EUR',
    'DK': 'DKK',
    'FR': 'EUR',
    'CH': 'CHF',
    'ES': 'EUR',
    'LU': 'EUR',
    'PE': 'PEN',
    'SE': 'SEK',
    'SI': 'EUR',
    'MT': 'EUR',
    'TH': 'THB',
    'VN': 'VND',
    'MY': 'MYR',
    'LT': 'EUR',
    'NL': 'EUR',
    'SK': 'EUR',
    'IT': 'EUR',
    'RU': 'RUB',
    'NO': 'NOK',
    'LV': 'EUR',
    'MX': 'MXN',
    'PT': 'EUR',
    'ME': 'EUR',
    'GB': 'GBP',
    'PH': 'PHP',
    'KR': 'KRW',
    'SG': 'SGD',
    'PL': 'PLN',
    'NZ': 'NZD',
    'TW': 'TWD',
    'JP': 'JPY',
    'IL': 'ILS',
    'HU': 'HUF',
    'CN': 'CNY',
};
export const paypalSupportedCurrencies = [
    'USD',
    'AUD',
    'BRL',
    'CAD',
    'CNY',
    'CZK',
    'DKK',
    'EUR',
    'HKD',
    'HUF',
    'ILS',
    'JPY',
    'MYR',
    'MXN',
    'TWD',
    'NZD',
    'NOK',
    'PHP',
    'PLN',
    'GBP',
    'RUB',
    'SGD',
    'SEK',
    'CHF',
    'THB',
];
