import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["data-content"];
import { computed } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'HorizontalSeparator',
    props: {
        inlineMessage: {
            type: String,
            default: '',
        },
    },
    setup(__props) {
        const classes = computed(() => {
            return {
                hr: true,
            };
        });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("hr", {
                class: _normalizeClass(classes.value),
                "data-content": __props.inlineMessage
            }, null, 10, _hoisted_1));
        };
    }
});
